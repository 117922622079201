import React, { useEffect, useLayoutEffect, useState } from 'react';
import './index.scss';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const Navbar = () => {
  const [position, setPosition] = useState(0);
  const [width, height] = useWindowSize();

  useScrollPosition(({ currPos }) => {
    setPosition(Math.abs(currPos.y));
  });

  const divIds = [
    'missions',
    'moto',
    'skills',
    'sources',
    'clients',
    'founders',
    'contact',
  ];

  const divPositions = divIds.map((id) => {
    const element = document.getElementById(id);
    if (!element) return 0;
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = element.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;
    return offset - 64;
  });

  const isDivNotCalculated = divPositions
    .map((e) => e <= 0)
    .reduce((e1, e2) => e1 && e2);
  const isNavbarVisible = !isDivNotCalculated && position > divPositions[0];
  const isItemActive = (divId) => {
    if (isDivNotCalculated) return false;
    const index = divIds.indexOf(divId);
    if (index < 6)
      return (
        position >= divPositions[index] && position < divPositions[index + 1]
      );

    return position >= divPositions[index];
  };

  const navigateTo = (href) => {
    if (!href) {
      document.getElementById('sliders').scrollIntoView({
        behavior: 'smooth',
      });
      return;
    }
    document.getElementById(href).scrollIntoView({
      behavior: 'smooth',
    });
  };
  const NavBarItem = ({ title, href }) => {
    return (
      <div
        className={`component__navbar__content__item ${
          isItemActive(href) ? 'component__navbar__content__item--active' : ''
        }`}
        onClick={() => navigateTo(href)}
      >
        {title}
      </div>
    );
  };

  return (
    <div
      className={`component__navbar ${
        isNavbarVisible ? 'component__navbar--visible' : ''
      } c-primary app-navbar-top hide-on-mobile`}
    >
      <div>
        <NavBarItem title="OUR MISSIONS" href="missions" />
      </div>
      <div className="component__navbar__content__item--credo">
        <NavBarItem title="OUR MOTO" href="moto" />
      </div>
      <div className="component__navbar__content__row">
        <img
          src="/images/logo_blue.png"
          alt="logo"
          onClick={() => navigateTo(null)}
        />
        <div>
          <div>
            <NavBarItem title="OUR SKILLS" href="skills" />
          </div>
          <div>
            <NavBarItem title="OUR SOURCES OF PRIDE" href="sources" />
          </div>
          <div>
            <NavBarItem title="OUR CLIENTS" href="clients" />
          </div>
        </div>
      </div>
      <div className="component__navbar__content__item--founders">
        <NavBarItem title="THE FOUNDERS" href="founders" />
      </div>
      <div>
        <NavBarItem title="CONTACT US" href="contact" />
      </div>
    </div>
  );
};
