import React from 'react';
import './index.scss';
import { useApp } from '../../helpers/index';

export default () => {
  const { menuOpened, toggleMenu } = useApp();

  const navigateTo = (href) => {
    toggleMenu();
    if (!href) {
      return window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    document.getElementById(href).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const SideMenuItem = ({ title, href }) => {
    return (
      <div
        onClick={() => navigateTo(href)}
        className="component__mobile__menu__content__item c-white"
      >
        {title}
      </div>
    );
  };

  const MENU_ITEMS = [
    { title: 'OUR MISSIONS', href: 'missions' },
    { title: 'OUR MOTO', href: 'moto' },
    { title: 'OUR SKILLS', href: 'skills' },
    { title: 'OUR SOURCES OF PRIDE', href: 'sources' },
    { title: 'OUR CLIENTS', href: 'clients' },
    { title: 'THE FOUNDERS', href: 'founders' },
    { title: 'CONTACT US', href: 'contact' },
  ];

  return (
    <div
      className={`component__mobile__menu show-on-mobile app-sidemenu-top ${
        menuOpened ? 'component__mobile__menu--opened' : ''
      }`}
    >
      <div className="component__mobile__menu__content">
        <div className="component__mobile__menu__content__items">
          {MENU_ITEMS.map((menu) => (
            <SideMenuItem {...menu} key={menu.href} />
          ))}
        </div>
      </div>
      <div className="component__mobile__menu__footer">
        <img src="/images/logo.png" alt="logo" />
      </div>
    </div>
  );
};
