import React, { createContext } from 'react';

const defaultValue = {
  menuOpened: false,
  toggleMenu: () => {},
};

const AppContext = createContext(defaultValue);

export default AppContext;
