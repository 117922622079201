import React, { useState } from "react";
import { validateEmail } from "../../utils";
import "./index.scss";

const YellowLetter = ({ children }) => (
  <span className="text--yellow">{children}</span>
);

const CommentSection = ({
  title,
  description,
  photo,
  name,
  professions,
  linkedIn,
}) => {
  return (
    <div className="container__comment c-white">
      <div className="container__comment__title">{title}</div>
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className="container__comment__description"
      />
      <div className="container__comment__info">
        <img
          alt="avatar"
          src={photo}
          className="container__comment__info__avatar"
        />
        <div className="container__comment__info__details">
          <div className="container__comment__info__details__name">{name}</div>
          {professions.map((pro, index) => (
            <div
              className="container__comment__info__details__profession"
              key={index}
            >
              {pro}
            </div>
          ))}
          {/* <a
            href={linkedIn}
            target="_blank"
            className="container__comment__info__details__linked_in"
          >
            in
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default () => {
  const initialState = { name: "", email: "", phone: "", loading: false };
  const [state, setState] = useState(initialState);

  const onChange = (event) => {
    event.persist();
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onTalk = () => {
    document.getElementById("section__talk").scrollIntoView({
      behavior: "smooth",
    });
  };
  const onStart = () => {
    document.getElementById("section__hero").scrollIntoView({
      behavior: "smooth",
    });
  };
  const onSubmit = (event) => {
    event.preventDefault();

    setState((prevState) => ({ ...prevState, loading: true }));
    fetch("https://thomaria.com/v1/talk", {
      method: "post",
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setState(initialState);
        alert("Thanks for your contacting us!");
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
        alert("Something went wrong!");
      });
  };
  const isDisabled =
    Object.keys(state)
      .map((key) => state[key] === "")
      .reduce((e1, e2) => e1 || e2) || !validateEmail(state.email);
  return (
    <div className="page__new_main">
      <div className="page__new_main__slider app-header-bottom">
        <div />
        <img
          className="page__new_main__slider__logo"
          alt="logo"
          src="/images/logo_thomaria.png"
        />
        <div className="page__new_main__slider__bottom c-white">
          <div className="page__new_main__slider__bottom__label hide-on-mobile">
            NO MEANS IS AN END IN ITSELF
          </div>
          <div className="page__new_main__slider__bottom__label show-on-mobile">
            NO MEANS IS
            <br />
            AN END IN ITSELF
          </div>
          <div className="page__new_main__slider__bottom__btns">
            <div onClick={onStart}>LET'S START</div>
            <div onClick={onTalk}>LET'S TALK</div>
          </div>
        </div>
      </div>
      <div className="page__new_main__ellipse">
        <img
          alt="ellipse"
          src="/images/new/ellipse.png"
          className="page__new_main__ellipse__img"
        />
      </div>
      <div className="page__new_main__hero" id="section__hero">
        <div className="page__new_main__hero__circles">
          <div className="page__new_main__hero__circles__circle" />
          <div className="page__new_main__hero__circles__circle__bottom">
            <div className="page__new_main__hero__circles__circle" />
            <div className="page__new_main__hero__circles__circle" />
          </div>
        </div>
        <div className="page__new_main__hero__title c-white">
          <div className="page__new_main__hero__title__text hide-on-mobile">
            <strong>T</strong>homaria was founded in <strong>2015</strong>, to find and <br />create effective solutions for
            your needs.<br />
            <strong>T</strong>he solution can take a form of an app
            <br />
            <strong>(A</strong>vert <strong>S</strong>ecurity for{" "}
            <strong>A</strong>
            pave<strong>)</strong>, a product <strong>(</strong>digital
            <br />
            bracelet for <strong>C</strong>lub <strong>M</strong>ed
            <strong>)</strong>, a user experience path
            <br />
            <strong>(S</strong>alesforce for <strong>C</strong>ovea
            <strong>)</strong> or ongoing every day
            <br />
            support on your most important projects. <br />
            <span className="page__new_main__hero__title__text__name">

              <br />
              The Founders
            </span>
          </div>
          <div className="page__new_main__hero__title__text show-on-mobile">
            <strong>T</strong>homaria was founded in <strong>2015</strong>, by{" "}
            <strong>T</strong>homas and&nbsp;
            <strong>M</strong>arija, to find and create effective solutions for
            your needs. <strong>T</strong>he solution can take a form of an app
            <strong>(A</strong>vert <strong>S</strong>ecurity for{" "}
            <strong>A</strong>
            pave<strong>)</strong>, a product <strong>(</strong>digital bracelet
            for <strong>C</strong>lub <strong>M</strong>ed
            <strong>)</strong>, a user experience path
            <strong>(S</strong>alesforce for <strong>C</strong>ovea
            <strong>)</strong> or ongoing every day support on your most
            important projects.
            <br />
            <span className="page__new_main__hero__title__text__name">
              <strong>M</strong>arija & <strong>T</strong>homas
            </span>
          </div>
        </div>
      </div>

      <div className="page__new_main__work_with">
        <div className="page__new_main__work_with__content c-white page__sub__content">
          <div className="page__new_main__work_with__content__title">
            we like to work with
          </div>
          <div className="page__new_main__work_with__content__comments hide-on-mobile">
            <YellowLetter>SAINT GOBAIN</YellowLetter>
            LOUIS VUITTON<YellowLetter>APAVE</YellowLetter>
            <br />
            COVEA<YellowLetter>PSA GROUP</YellowLetter>AMUNDI
            <br />
            <YellowLetter>FOSUN HOSPITALITY</YellowLetter>BBDOLITHUANIA
            <br />
            <YellowLetter>INGENIOUS</YellowLetter>CLUB MED
            <YellowLetter>WARNER BROS</YellowLetter>
            <br />
            CITROEN<YellowLetter>SNOBAPP</YellowLetter>
          </div>
          <div className="page__new_main__work_with__content__comments show-on-mobile">
            <YellowLetter>SAINT GOBAIN</YellowLetter>
            <br />
            LOUIS VUITTON<YellowLetter>APAVE</YellowLetter>
            <br />
            COVEA<YellowLetter>PSA GROUP</YellowLetter>
            <br />
            AMUNDI
            <YellowLetter>INGENIOUS</YellowLetter>
            <br />
            BBDOLITHUANIA
            <br />
            <YellowLetter>FOSUN HOSPITALITY</YellowLetter>
            <br />
            CLUB MED
            <YellowLetter>WARNER BROS</YellowLetter>
            <br />
            CITROEN<YellowLetter>SNOBAPP</YellowLetter>
          </div>
        </div>
      </div>

      <div className="page__new_main__proud__design page__sub__content">
        <img
          alt="cola"
          src="/images/new/bg-2.png"
          className="page__new_main__proud__design__img"
        />
        <div className="page__new_main__proud__design__text c-white">
          <div className="page__new_main__proud__design__text__proud_of">
            proud of
          </div>
          <div className="page__new_main__proud__design__text__new_design">
            new design
            <br />
            digital bracelet
          </div>
          <div className="page__new_main__proud__design__text__thomaria">
            thomaria for club med
          </div>
        </div>
      </div>

      <div className="page__new_main__proud_director page__sub__content">
        <img
          alt="building"
          src="/images/new/bg-3.png"
          className="page__new_main__proud_director__img"
        />
        <div className="page__new_main__proud_director__text c-white">
          <div className="page__new_main__proud_director__text__proud_of">
            proud to have been
          </div>
          <div className="page__new_main__proud_director__text__new_design">
            digital innovation
            <br />
            project director
          </div>
          <div className="page__new_main__proud_director__text__thomaria">
            marija for saint gobain ww
          </div>
        </div>
      </div>

      <div className="page__new_main__proud_director page__sub__content">
        <img
          alt="building"
          src="/images/new/bg-4.png"
          className="page__new_main__proud_director__img"
        />
        <div className="page__new_main__proud_director__text c-white">
          <div className="page__new_main__proud_director__text__proud_of">
            proud to have been
          </div>
          <div className="page__new_main__proud_director__text__new_design">
            digital experience
            <br />
            program director
          </div>
          <div className="page__new_main__proud_director__text__thomaria">
            thomas for club med china
          </div>
        </div>
      </div>

      <div className="page__new_main__proud_director page__sub__content">
        <img
          alt="building"
          src="/images/new/bg-5.png"
          className="page__new_main__proud_director__img"
        />
        <div className="page__new_main__proud_director__text c-white">
          <div className="page__new_main__proud_director__text__proud_of">
            proud to have been
          </div>
          <div className="page__new_main__proud_director__text__new_design">
            one retail project
            <br />
            coordinator
          </div>
          <div className="page__new_main__proud_director__text__thomaria">
            marija for louis vuitton
          </div>
        </div>
      </div>

      <div className="page__new_main__proud_also page__sub__content c-white">
        <div className="page__new_main__proud__title">we're proud also of</div>
        <div className="page__new_main__proud_also__content">
          <div className="page__new_main__proud_also__content__item">
            <YellowLetter>APAVE GROUP</YellowLetter>
            <div>DIGITAL INNOVATION & PROGRAM DIRECTOR GROUP</div>
          </div>

          <div className="page__new_main__proud_also__content__item">
            <YellowLetter>covea</YellowLetter>
            <div>USER EXPERIENCE PROJECT SALESFORCE</div>
          </div>
          <div className="page__new_main__proud_also__content__item">
            <YellowLetter>CLUB MED WW</YellowLetter>
            <div>DIGITAL EXPERIENCE CLUB MED WORLDWIDE</div>
          </div>
          <div className="page__new_main__proud_also__content__item">
            <YellowLetter>PSA GROUP</YellowLetter>
            <div>DIGITAL CONFIGURATOR POINT OF SALES</div>
          </div>
          <div className="page__new_main__proud_also__content__item">
            <YellowLetter>APAVE GROUP</YellowLetter>
            <div>DEVELOPMENT MOBILE APP AVERT</div>
            <div>PREVENTICA AWARD PARIS 2019</div>
          </div>
        </div>
      </div>

      <div className="page__new_main__skills page__sub__content">
        <div className="page__new_main__skills__row">
          <div className="page__new_main__skills__row__item">
            <div className="page__new_main__skills__row__item__title">
              OUR FIELDS OF EXPERTISE
            </div>
            <div className="page__new_main__skills__row__item__content">
              DIGITAL INNOVATION &<br />
              TRANSFORMATION
              <br />
              PROJECT MANAGEMENT
              <br />
              SOLUTION FINDERS &<br /> CREATORS
            </div>
          </div>
        </div>
        <div className="page__new_main__skills__row">
          <div className="page__new_main__skills__row__item">
            <div className="page__new_main__skills__row__item__title">
              OUR technical toolbox
            </div>
            <div className="page__new_main__skills__row__item__content">
              reactjs, vue js,
              <br />
              angular, react native,
              <br />
              nativescript, ionic
              <br />
              framework, drupal
              <br />
              salesforce, oracle
              <br />
              retail solution, nfc,
              <br />
              rfid
            </div>
          </div>
        </div>
      </div>

      <div className="page__new_main__thomaria_img" />

      <div className="page__new_main__founders c-white">
        <div className="page__new_main__founder">
          <div className="page__new_main__founder__name">THOMAS</div>
          <div className="page__new_main__founder__name__comments">
            <CommentSection
              title="A colleague says:"
              description={`I had the opportunity to work with Thomas on the Club Med JoyView digital experience project, where he played the project manager role.<br/><br/>
I have known Thomas as a very diligent and highly responsible person. He understood both the French and Chinese culture and way of working. In addition, with his can-do attitude, he was a real problem solver for the project and did a great job in efficient and smooth communication between Shanghai and Paris. I highly appreciate the collaboration with Thomas and look forward to have the chance to work with him again in the future.`}
              photo="/images/new/yuqing.png"
              name="Yuqing Guo - Partner"
              professions={[
                "China Center of Excellence",
                "PricewaterhouseCoopers - Strategy",
              ]}
              linkedIn="https://linkedin.com"
            />
            <CommentSection
              title="A client says:"
              description={`Thomaria team was our strategic partner during Apave group website upgrade. The goal was to create a client interface full of digital services to reflect changing industry and above business evolution. Their quality contribution was present from defining the requirement, development of mobile and desktop applications to ensuring their evolution once in production while always focusing on ROI and ever better user experience. Their professional advice and hands on approach followed from advice during conception to production version upgrades. Proactive and agile, they found the most effective technological choices and therefore gained confidence as our strategic partner for digital marketing and Client Digital Factory projects. Avert inOne application developed for us have won the 2019 innovation prize.`}
              photo="/images/new/laurence.png"
              name="Laurence Barriere"
              professions={["Marketing Director Group", "Apave Group"]}
              linkedIn="https://linkedin.com"
            />
          </div>
        </div>
        <div className="page__new_main__founder">
          <div className="page__new_main__founder__name">MARIJA</div>
          <div className="page__new_main__founder__name__comments">
            <CommentSection
              title="A colleague says:"
              description={`I worked with Marija on Covéa project, and witnessed great focus and determination to deliver optimal user experience.`}
              photo="/images/new/slim.png"
              name="Slim Ben Atallah"
              professions={["Directeur du programme OSE", "Covea Group"]}
              linkedIn="https://linkedin.com"
            />
            <CommentSection
              title="A client says:"
              description={`Marija perfectly fit into the project team and more generally to the LV EMEA entity. In a challenging context, she was proactive to enhance our deployment process. She became a key element thanks to her rigour and enthousiasm.`}
              photo="/images/new/frederique.png"
              name="Frederique BLANCHARD"
              professions={[
                "Head of EMEA Retail",
                "Omnichannel Projects",
                "Louis Vuitton - LVMH",
              ]}
              linkedIn="https://linkedin.com"
            />
          </div>
        </div>
      </div>

      <div className="page__new_main__contact" id="section__talk">
        {state.loading && (
          <div className="page__new_main__contact__loader">
            <div className="sk-chase">
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
            </div>
          </div>
        )}
        <div className="page__new_main__contact__content">
          <div className="page__new_main__contact__content__title c-white">
            LET'S TALK
          </div>
          <div className="page__new_main__contact__content__comment c-black">
            You have a project, an idea or an issue...
          </div>
          <div className="page__new_main__contact__content__form">
            <form onSubmit={onSubmit}>
              <div className="page__new_main__contact__content__form__row">
                <input
                  className="page__new_main__contact__content__form__row__item"
                  name="name"
                  onChange={onChange}
                  placeholder="NAME"
                />
                <input
                  className="page__new_main__contact__content__form__row__item"
                  name="phone"
                  onChange={onChange}
                  placeholder="PHONE"
                />
              </div>
              <div className="page__new_main__contact__content__form__row">
                <input
                  className="page__new_main__contact__content__form__row__item"
                  name="email"
                  onChange={onChange}
                  placeholder="EMAIL"
                />
                <button
                  disabled={isDisabled}
                  type="submit"
                  className="page__new_main__contact__content__form__row__item page__new_main__contact__content__form__row__item--button"
                >
                  LET'S GO!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="page__new_main__footer">
        <img
          alt="logo"
          src="/images/new/logo_yellow.png"
          className="page__new_main__footer__logo"
        />
        <div className="page__new_main__footer__copyright">
          copyright 2020 thomaria
        </div>
      </div>
    </div>
  );
};
