import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApp } from "../../helpers/index";

export const Header = () => {
  const { menuOpened, toggleMenu } = useApp();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isFR = currentLang === "fr";
  const onEN = () => {
    i18n.changeLanguage("en-US");
  };
  const onFR = () => {
    i18n.changeLanguage("fr");
  };
  return (
    <div className="component__header app-header bg-primary">
      <div className="component__header__content">
        <div
          className="component__header__content__nav"
          onClick={() => {
            document.getElementById("sliders").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <img
            src="/images/logo.png"
            alt="logo"
            className="component__header__content__nav__img"
          />
        </div>
        <div className="component__header__content__right">
          <div
            className={`component__header__content__right__item ${
              isFR ? "" : "component__header__content__right__item--active"
            } font-semiBold c-white`}
            onClick={onEN}
          >
            EN
          </div>
          {/* <div
            className={`component__header__content__right__item ${
              isFR ? 'component__header__content__right__item--active' : ''
            } font-semiBold c-white`}
            onClick={onFR}
          >
            FR
          </div> */}
          <div
            className="component__header__content__right__item"
            onClick={() => {
              document.getElementById("section__talk").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <img
              src="/images/envelope.png"
              alt="logo"
              className="component__header__content__right__item__img"
            />
          </div>
          {/* <div
            className="component__header__content__right__item show-on-mobile"
            onClick={toggleMenu}
          >
            <img
              src={
                menuOpened ? "/images/menu_selected.png" : "/images/menu.png"
              }
              alt="logo"
              className="component__header__content__right__item__img"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
