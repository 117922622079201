import React from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';

export const DemoLayout = withRouter((props) => {
  return (
    <div className="layout__demo">
      <div className="layout__demo__content bg-white">{props.children}</div>
    </div>
  );
});
