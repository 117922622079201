import React, { useState } from 'react';
import './index.scss';
import { Header, MobileMenu, Navbar } from '../../components';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../contexts';

export const MainLayout = withRouter((props) => {
  const [state, setState] = useState({
    menuOpened: false,
  });

  const toggleMenu = () =>
    setState((prevState) => ({
      ...prevState,
      menuOpened: !prevState.menuOpened,
    }));

  return (
    <AppContext.Provider value={{ ...state, toggleMenu }}>
      <div className="layout__main">
        <Header />
        <div className="layout__main__content bg-white">
          <Navbar />
          <MobileMenu />
          {props.children}
        </div>
      </div>
    </AppContext.Provider>
  );
});
