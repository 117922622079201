import React, { Suspense } from "react";
import "./App.scss";
import { MainLayout, DemoLayout } from "./layouts";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MainPage, NewMainPage, DemoPage } from "./pages";
import "pure-react-carousel/dist/react-carousel.es.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <MainLayout {...props}>
                  <NewMainPage />
                </MainLayout>
              )}
            />
            <Route
              path="/beta"
              component={(props) => (
                <DemoLayout {...props}>
                  <DemoPage />
                </DemoLayout>
              )}
            />
          </Switch>
        </BrowserRouter>{" "}
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
