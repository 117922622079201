import React from 'react';
import { SliderSection } from './sections/SliderSection';

export default () => {
  return (
    <div>
      <SliderSection />
    </div>
  );
};
