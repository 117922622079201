import React from 'react';
import './index.scss';

export const SliderSection = () => {
  return (
    <div className="page__demo_slider">
      <div className="page__demo__section__slider page__demo__section page-top-padding">
        <div className="page__demo__section__slider__content c-white">
          <div className="page__demo__section__slider__content__circle page__demo__section__slider__content__circle--top border-white">
            <img src="/images/logo_thomaria.png" alt="logo" />
          </div>
          <div className="page__demo__section__slider__content__circle__bottom">
            <div className="page__demo__section__slider__content__circle page__demo__section__slider__content__circle--bottom-left border-white">
              <span>EFFECTIVE SOLUTIONS</span>
            </div>
            <div className="page__demo__section__slider__content__circle page__demo__section__slider__content__circle--bottom-right border-white">
              <span>CREATORS & FINDERS</span>
            </div>
          </div>
        </div>
      </div>
      <div className="page__demo__section__slider__bottom">
        <div className="c-primary">COMING SOON</div>
      </div>
      <div className="page__footer bg-primary">
        <a href="mailto:hello@thomaria.com">
          <img src="/images/envelope.png" alt="envelope" />
        </a>
      </div>
    </div>
  );
};
